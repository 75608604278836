<template>
  <div>
    <b-alert
      show
      variant="light"
    >
      <h4 class="alert-heading">
        <feather-icon
          icon="InfoIcon"
          size="18"
        /> Notice
      </h4>
      <p
        v-if="platformAllowed"
        style="padding:10px 10px 0px 50px"
      >
        If you are not redirected to your platform, please click <a
          :href="url"
          target="_blank"
        >here</a>.
      </p>
      <p
        v-else
        style="padding:10px 10px 0px 50px"
      >
        Sorry, this feature is not available.
      </p>
      <hr>
    </b-alert>
  </div>
</template>

<script>

import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {

  },
  data() {
    return {
      url: '',
      platformAllowed: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.goToFlatform()
  },
  methods: {
    async goToFlatform() {
      const userStatus = Number(this.userData.user_status)
      const { uid } = this.userData
      if (userStatus >= 4 && userStatus !== 6) {
        this.platformAllowed = true
      }

      const setting = await this.$store.dispatch('setting/fetchSetting', uid)
      if (setting.network_type === 'domain') {
        this.url = `https://${setting.sub_admin}.${setting.domain}`
      } else {
        this.url = `https://${setting.subdomain}.trafficmanager.com`
      }

      if (this.platformAllowed) {
        window.open(this.url, '_blank')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
